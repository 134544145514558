import { Text, TextProps } from '@chakra-ui/react'

export default function Money({
  children,
  deduction = false,
  cents = true,
  ...props
}: { children: number; deduction?: boolean; cents?: boolean } & TextProps): JSX.Element {
  const formatted = `$${children.toFixed(cents ? 2 : 0)}`
  const prefix = deduction ? '-' : ''
  return (
    <Text as="span" {...props}>
      {prefix}
      {formatted}
    </Text>
  )
}
