export const IS_PROD = process.env.NODE_ENV === 'production'

export const SERVER_URL = IS_PROD ? 'https://api.keepit.cool' : 'http://localhost:8000'

export const SENTRY_DSN = IS_PROD
  ? 'https://0c4b75ab1d844d0caf67e791339c1dcf@o4503976588673024.ingest.sentry.io/4503976590573569'
  : ''

export const POSTHOG_API_KEY = IS_PROD
  ? 'phc_z0dxGvtmVkjG6KhrIjVSRQntacyuXFJ0V1iGbsaJvmS'
  : 'phc_yiMs324uFtmh68BZ0no4PRXB0ELL4dR6Vz9GQOMV2rN'
