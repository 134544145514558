import { createStandaloneToast } from '@chakra-ui/react'
import { connect, kea, listeners, path } from 'kea'
import { loaders } from 'kea-loaders'
import { router } from 'kea-router'
import { api } from 'lib/Api'
import { BillingProvider, PlanTier } from 'lib/Types'
import { userLogic } from 'lib/logics/userLogic'

import type { billingRedirectLogicType } from './billingRedirectLogicType'

const { toast } = createStandaloneToast()

export const billingRedirectLogic = kea<billingRedirectLogicType>([
  path(['scenes', 'billing-redirects', 'billingRedirectLogic']),
  connect({
    actions: [userLogic, ['loadUser']],
  }),
  loaders(() => ({
    finalizeSaasPlanSelection: [
      { success: false },
      {
        loadFinalizeSaasPlanSelection: async (params: { plan?: PlanTier; provider?: BillingProvider }) =>
          await api.create(`/api/billing/plans/finalize/`, params),
      },
    ],
  })),
  listeners(({ actions }) => ({
    loadFinalizeSaasPlanSelectionSuccess: async () => {
      actions.loadUser()
      router.actions.push('/')

      toast({
        title: '🎉 Plan successfully updated!',
        description: 'Welcome to Keep It Cool 🧊',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
  })),
])
