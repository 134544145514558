import { SERVER_URL } from './Constants'
import { authLogic } from './logics/authLogic'

async function getJSONOrThrow(response: Response): Promise<any> {
  try {
    return await response.json()
  } catch (e) {
    return { statusText: response.statusText }
  }
}

async function getBlobOrThrow(response: Response): Promise<any> {
  try {
    return await response.blob()
  } catch (e) {
    return { statusText: response.statusText }
  }
}

function reportError(method: string, url: string, response: Response, startTime: number): void {
  const duration = new Date().getTime() - startTime
  const pathname = new URL(url, location.origin).pathname
  const message = `
  API Error
  ${method} ${pathname} failed:
  ${response.status} ${duration}ms`
  ;(window as any)?.Sentry?.captureMessage(message)
}

export const normalizeUrl = (url: string): string => {
  // If the URL is relative, prepend the API URL
  if (!url.startsWith('http')) {
    if (!url.startsWith('/')) {
      url = '/' + url
    }

    url = SERVER_URL + url + (url.indexOf('?') === -1 && url[url.length - 1] !== '/' ? '/' : '')
  }
  return url
}

const getAuthHeaders = (): Record<string, string> => {
  if (!authLogic.isMounted()) {
    authLogic.mount()
  }
  const { authCredentials, iFrameDemoAuthCredentials } = authLogic.values

  if (iFrameDemoAuthCredentials.apiKey) {
    return {
      Authorization: `Api-Key ${iFrameDemoAuthCredentials.apiKey}`,
    }
  }

  if (authCredentials.access) {
    return {
      Authorization: `Bearer ${authCredentials.access}`,
    }
  }
  return {}
}

export const api = {
  async get(url: string, urlParams?: Record<string, any>): Promise<any> {
    url = normalizeUrl(url)
    const newUrl = new URL(url, location.origin)
    if (urlParams) {
      Object.entries(urlParams).forEach(([key, value]) => newUrl.searchParams.append(key, value))
    }
    const authHeaders = getAuthHeaders()
    const res = await api.getRaw(newUrl.toString(), {
      Accept: 'application/json',
      ...authHeaders,
    })
    return await getJSONOrThrow(res)
  },

  async getBlob(url: string): Promise<any> {
    url = normalizeUrl(url)
    const authHeaders = getAuthHeaders()
    const res = await api.getRaw(url, {
      Accept: 'application/json',
      ...authHeaders,
    })
    console.log(res)

    return await getBlobOrThrow(res)
  },

  async getRaw(url: string, headers?: Record<string, string>): Promise<Response> {
    let response
    const startTime = new Date().getTime()
    try {
      response = await fetch(url, { headers })
    } catch (e) {
      throw { status: 0, message: e }
    }

    if (!response.ok) {
      reportError('GET', url, response, startTime)
      const data = await getJSONOrThrow(response)
      throw { status: response.status, ...data }
    }
    return response
  },

  async update(url: string, data: any): Promise<any> {
    url = normalizeUrl(url)
    const isFormData = data instanceof FormData
    const startTime = new Date().getTime()
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
        ...getAuthHeaders(),
      },
      body: isFormData ? data : JSON.stringify(data),
    })

    if (!response.ok) {
      reportError('PATCH', url, response, startTime)
      const jsonData = await getJSONOrThrow(response)
      if (Array.isArray(jsonData)) {
        throw jsonData
      }
      throw { status: response.status, ...jsonData }
    }
    return await getJSONOrThrow(response)
  },

  async create(url: string, data?: any, signal?: AbortSignal): Promise<any> {
    url = normalizeUrl(url)
    const isFormData = data instanceof FormData
    const startTime = new Date().getTime()
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
        ...getAuthHeaders(),
      },
      body: data ? (isFormData ? data : JSON.stringify(data)) : undefined,
      signal,
    })

    if (!response.ok) {
      reportError('POST', url, response, startTime)
      const jsonData = await getJSONOrThrow(response)
      if (Array.isArray(jsonData)) {
        throw jsonData
      }
      throw { status: response.status, ...jsonData }
    }
    return await getJSONOrThrow(response)
  },

  async delete(url: string): Promise<any> {
    url = normalizeUrl(url)
    const startTime = new Date().getTime()
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...getAuthHeaders(),
      },
    })

    if (!response.ok) {
      reportError('DELETE', url, response, startTime)
      const data = await getJSONOrThrow(response)
      throw { status: response.status, ...data }
    }
    return response
  },
}
