import {
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useActions, useValues } from 'kea'
import { usageGuardLogic } from 'lib/logics/usageGuardLogic'
import { userLogic } from 'lib/logics/userLogic'

import ChoosePlan from './ChoosePlan'
import { planManagementLogic } from './planManagementLogic'

export function SaasPlanManagementModal(): JSX.Element | null {
  const { isPlanManagementModalOpen } = useValues(planManagementLogic)
  const { userHasNoChoiceButToChoosePlan } = useValues(usageGuardLogic)
  const { closeModal } = useActions(planManagementLogic)
  const { organizationIsSubscriber, user } = useValues(userLogic)

  if (user === null) {
    return null
  }

  return (
    <Modal onClose={closeModal} isOpen={isPlanManagementModalOpen} isCentered>
      <ModalOverlay />
      <ModalContent maxW="1800px" mx={10} pb={10} backgroundColor={'brandTan'} borderRadius={'2xl'} w="fit-content">
        <ModalHeader textAlign="center">
          <Flex direction={'column'} justify={'center'}>
            <Image src={'/kic-logo-no-text.svg'} alt={'Keep it Cool'} maxH={'80px'} />
            <Heading>{organizationIsSubscriber ? 'Manage Your Plan' : 'Choose a Plan'}</Heading>
          </Flex>
        </ModalHeader>
        {userHasNoChoiceButToChoosePlan ? null : <ModalCloseButton />}
        <ModalBody>
          <ChoosePlan />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
