import * as Sentry from '@sentry/react'
import { POSTHOG_API_KEY, SENTRY_DSN } from 'lib/Constants'
import posthog from 'posthog-js'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import './index.css'
import { initKea } from './initKea'

Sentry.init({
  dsn: SENTRY_DSN,
})

posthog.init(POSTHOG_API_KEY, {
  api_host: 'https://d1yioaymebheg4.cloudfront.net',
  session_recording: {
    maskAllInputs: false,
  },
})
initKea()

const root = document.getElementById('root')
if (root) {
  createRoot(root).render(<App />)
} else {
  console.error(`Could not find element matching "#root"`)
}
