import { actions, events, kea, path, reducers, selectors } from 'kea'
import { loaders } from 'kea-loaders'
import { api } from 'lib/Api'
import { PackagingConfig } from 'lib/Types'

import type { packagingConfigModelLogicType } from './packagingConfigModelLogicType'

export const packagingConfigModelLogic = kea<packagingConfigModelLogicType>([
  path(['scenes', 'packaging-config-settings', 'packagingConfigModelLogic']),
  actions({
    loadPackagingConfigs: true,
  }),
  reducers({
    packagingConfigs: [
      [] as PackagingConfig[],
      {
        updatePackagingConfigList: (state, { packagingConfig }) => {
          const index = state.findIndex((p) => p.uuid === packagingConfig.uuid)
          if (index === -1) {
            return [...state, packagingConfig]
          }
          return [...state.slice(0, index), packagingConfig, ...state.slice(index + 1)]
        },
      },
    ],
  }),
  loaders(() => ({
    packagingConfigs: [
      [] as PackagingConfig[],
      {
        loadPackagingConfigs: async (_, breakpoint) => {
          breakpoint()
          const url = '/api/packaging_configs/'
          const response = await api.get(url)
          return response['results']
        },
      },
    ],
  })),
  selectors(() => ({
    getPackagingConfigFromList: [
      (s) => [s.packagingConfigs],
      (packagingConfigs) => (packagingConfigId?: string) => {
        if (!packagingConfigId) {
          return null
        }
        return packagingConfigs.find((p) => p.uuid === packagingConfigId) ?? null
      },
    ],
    packagingConfigCount: [(s) => [s.packagingConfigs], (packagingConfigs) => packagingConfigs.length],
    packagingConfigById: [
      (s) => [s.packagingConfigs],
      (packagingConfigs) => {
        return packagingConfigs.reduce(
          (acc, packagingConfig) => ({ ...acc, [packagingConfig.uuid]: packagingConfig }),
          {} as Record<string, PackagingConfig>
        )
      },
    ],
    activePackagingConfigs: [
      (s) => [s.packagingConfigs],
      (packagingConfigs) => packagingConfigs.filter((p) => p.active),
    ],
    inactivePackagingConfigs: [
      (s) => [s.packagingConfigs],
      (packagingConfigs) => packagingConfigs.filter((p) => !p.active),
    ],
    activePackagingConfigCount: [
      (s) => [s.activePackagingConfigs],
      (activePackagingConfigs) => activePackagingConfigs.length,
    ],
  })),
  events(({ actions }) => ({
    afterMount: () => {
      actions.loadPackagingConfigs()
    },
  })),
])
