import { actions, kea, listeners, path, reducers, selectors } from 'kea'
import { router } from 'kea-router'

import type { authLogicType } from './authLogicType'

export interface AuthCredentials {
  access: string | null
  refresh: string | null
}

export const authLogic = kea<authLogicType>([
  path(['lib', 'logics', 'authLogic', 'authLogic']),
  actions({
    setAuthCredentials: (access: string | null, refresh: string | null) => ({ access, refresh }),
    setIFrameDemoAuthCredentials: (apiKey: string | null) => ({ apiKey }),
    logout: true,
  }),
  reducers({
    authCredentials: [
      {
        access: null,
        refresh: null,
      } as AuthCredentials,
      { persist: true },
      {
        setAuthCredentials: (_, { access, refresh }) => {
          return { access, refresh }
        },
        logout: () => ({ access: null, refresh: null }),
      },
    ],
    iFrameDemoAuthCredentials: [
      {
        apiKey: null as string | null,
      },
      {
        setIFrameDemoAuthCredentials: (_, { apiKey }) => {
          return { apiKey }
        },
      },
    ],
  }),
  selectors({
    isAuthenticated: [
      (s) => [s.authCredentials, s.iFrameDemoAuthCredentials],
      (authCredentials, iFrameDemoAuthCredentials) =>
        authCredentials.access !== null || iFrameDemoAuthCredentials.apiKey !== null,
    ],
  }),
  listeners({
    logout: () => {
      router.actions.push('/login')
    },
  }),
])
