import { actions, connect, kea, listeners, path } from 'kea'
import posthog from 'posthog-js'

import type { analyticsLogicType } from './analyticsLogicType'
import { authLogic } from './authLogic'
import { userLogic } from './userLogic'

export interface AuthCredentials {
  access: string | null
  refresh: string | null
}

export const analyticsLogic = kea<analyticsLogicType>([
  path(['lib', 'logics', 'analyticsLogic']),
  connect({
    values: [userLogic, ['user', 'organizationName']],
    actions: [userLogic, ['loadUser'], authLogic, ['logout']],
  }),
  actions({
    identify: true,
    capture: (event: string, properties?: Record<string, any>) => ({ event, properties }),
  }),
  listeners(({ values }) => ({
    identify: () => {
      if (values.user) {
        posthog.identify(values.user.uuid, {
          email: values.user.email,
          organization_name: values.organizationName,
        })
      }
    },
    capture: ({ event, properties }) => {
      posthog.capture(event, properties)
    },
    logout: () => {
      posthog.reset()
    },
  })),
])
