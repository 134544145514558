import { actions, events, kea, path, reducers, selectors } from 'kea'
import posthog from 'posthog-js'

import type { featureFlagLogicType } from './featureFlagsLogicType'

export const FEATURE_FLAGS = {
  INTERNAL_USERS: 'internal-users',
  PLAN_GATING: 'plan-gating',
  SHIP_STATION_REVAMP: 'ship-station-revamp',
}

export const featureFlagLogic = kea<featureFlagLogicType>([
  path(['lib', 'logics', 'featureFlagLogic']),
  actions({
    setFeatureFlags: (flags: string[], variants: Record<string, string | boolean>) => ({
      flags,
      variants,
    }),
  }),
  reducers({
    featureFlags: [
      {} as Record<string, string | boolean>,
      { persist: true },
      {
        setFeatureFlags: (_, { variants }) => variants,
      },
    ],
    receivedFeatureFlags: [
      false,
      {
        setFeatureFlags: () => true,
      },
    ],
  }),
  selectors({
    isInternalOnly: [(s) => [s.featureFlags], (featureFlags) => !featureFlags[FEATURE_FLAGS.INTERNAL_USERS]],
  }),
  events(({ actions }) => ({
    afterMount: () => {
      posthog.onFeatureFlags(actions.setFeatureFlags)
    },
  })),
])
