import { connect, events, kea, path, selectors } from 'kea'
import { OMSProvider } from 'lib/Types'
import { distributionCenterModelLogic } from 'lib/models/distributionCenterModelLogic'
import { packagingConfigModelLogic } from 'lib/models/packagingConfigModelLogic'
import { integrationsLogic } from 'scenes/integrations/integrationsLogic'

import { featureFlagLogic } from './featureFlagsLogic'
import type { usageGuardLogicType } from './usageGuardLogicType'
import { userLogic } from './userLogic'

export type UsageGauge = { max: number | null; current: number; name: string; usageAtMax: boolean }
export type GuardedFeatureWithLimit = 'distribution-centers' | 'packaging-configs' | 'recommendations'
export type GuardedFeature =
  | 'simulation-lab'
  | 'api-access'
  | 'shopify-integration'
  | 'shipstation-integration'
  | GuardedFeatureWithLimit

export const usageGuardLogic = kea<usageGuardLogicType>([
  path(['lib', 'logics', 'usageGuardLogic']),
  connect({
    values: [
      userLogic,
      ['organizationPlanTier', 'organizationUsageLimits', 'organizationCurrentWeekRecommendationCount'],
      distributionCenterModelLogic,
      ['activeDistributionCenterCount'],
      packagingConfigModelLogic,
      ['activePackagingConfigCount'],
      featureFlagLogic,
      ['featureFlags'],
      integrationsLogic,
      ['organizationHasIntegration'],
    ],
    actions: [userLogic, ['loadUser']],
  }),
  selectors({
    getUsageGaugeForFeature: [
      (selectors) => [
        selectors.organizationUsageLimits,
        selectors.activeDistributionCenterCount,
        selectors.activePackagingConfigCount,
        selectors.organizationCurrentWeekRecommendationCount,
      ],
      (
          organizationUsageLimits,
          activeDistributionCenterCount,
          activePackagingConfigCount,
          organizationCurrentWeekRecommendationCount
        ) =>
        (feature: GuardedFeatureWithLimit) => {
          if (!organizationUsageLimits) return null
          const gauges = {
            'distribution-centers': {
              current: activeDistributionCenterCount,
              max: organizationUsageLimits.distribution_centers,
              name: 'Distribution Centers',
              usageAtMax: activeDistributionCenterCount >= (organizationUsageLimits.distribution_centers ?? Infinity),
            },
            'packaging-configs': {
              current: activePackagingConfigCount,
              max: organizationUsageLimits.packaging_configs,
              name: 'Packaging Configs',
              usageAtMax: activePackagingConfigCount >= (organizationUsageLimits.packaging_configs ?? Infinity),
            },
            recommendations: {
              current: organizationCurrentWeekRecommendationCount,
              max: organizationUsageLimits.recommendations_weekly,
              name: 'Recommendations',
              usageAtMax:
                organizationCurrentWeekRecommendationCount >=
                (organizationUsageLimits.recommendations_weekly ?? Infinity),
            },
          }
          const gauge: UsageGauge = gauges[feature]
          return gauge
        },
    ],
    canAccessFeature: [
      (selectors) => [selectors.organizationUsageLimits, selectors.organizationHasIntegration],
      (organizationUsageLimits, organizationHasIntegration) => (feature: GuardedFeature) => {
        const featureToAccessFlagMap = {
          'simulation-lab': organizationUsageLimits?.simulation_lab ?? false,
          'api-access': organizationUsageLimits?.api_access ?? false,
          'distribution-centers': true,
          'packaging-configs': true,
          recommendations: true,
          // fall back to organizationHasIntegration() to grandfather in existing integrations
          'shopify-integration':
            organizationUsageLimits?.integrations || organizationHasIntegration(OMSProvider.Shopify),
          'shipstation-integration':
            organizationUsageLimits?.integrations || organizationHasIntegration(OMSProvider.ShipStation),
        }

        return featureToAccessFlagMap[feature]
      },
    ],
    userHasNoChoiceButToChoosePlan: [
      (selectors) => [selectors.organizationPlanTier],
      (organizationPlanTier) => {
        return organizationPlanTier === 'NO_PLAN'
      },
    ],
  }),
  events(({ actions }) => ({ beforeMount: [actions.loadUser] })),
])
