import { Tag } from '@chakra-ui/react'
import { useValues } from 'kea'
import { identifierToHuman } from 'lib/Utils'
import { userLogic } from 'lib/logics/userLogic'

export default function PlanTierTag(): JSX.Element | null {
  const { organizationPlanTier } = useValues(userLogic)
  if (!organizationPlanTier || organizationPlanTier === 'NO_PLAN' || organizationPlanTier === 'SENSORS_ONLY')
    return null

  return (
    <Tag size="sm" variant="outline" colorScheme="orange" opacity="90%" fontWeight={900}>
      {identifierToHuman(organizationPlanTier.toLowerCase(), 'title')}
    </Tag>
  )
}
