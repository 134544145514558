import { createStandaloneToast } from '@chakra-ui/react'
import { createMemoryHistory } from 'history'
import { resetContext } from 'kea'
import { formsPlugin } from 'kea-forms'
import { loadersPlugin } from 'kea-loaders'
import { localStoragePlugin } from 'kea-localstorage'
import { routerPlugin } from 'kea-router'
import { subscriptionsPlugin } from 'kea-subscriptions'
import { testUtilsPlugin } from 'kea-test-utils'
import { identifierToHuman } from 'lib/Utils'

const { toast } = createStandaloneToast()

const IS_TEST_MODE = process.env.NODE_ENV === 'test'

export function initKea(): void {
  const routerOptions: Record<string, any> = {}
  if (IS_TEST_MODE) {
    const history = createMemoryHistory()
    ;(history as any).pushState = history.push
    ;(history as any).replaceState = history.replace
    routerOptions.history = history
    routerOptions.location = history.location
  }

  resetContext({
    plugins: [
      routerPlugin(routerOptions),
      loadersPlugin({
        onFailure({ error, reducerKey, actionKey }: { error: any; reducerKey: string; actionKey: string }) {
          let errorMessage = `${identifierToHuman(actionKey)} on reducer ${identifierToHuman(reducerKey)} failed: ${
            error.status !== 0 ? error.detail || 'Keep it Cool may be offline' : 'Keep it Cool may be offline'
          }`
          // Attempt to get error message from server
          if (error.error) {
            errorMessage = error.error
          }
          toast({
            title: 'Network error',
            description: errorMessage,
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            isClosable: true,
          })
          console.error(error)
          ;(window as any).Sentry?.captureException(error)
        },
      }),
      formsPlugin,
      localStoragePlugin,
      subscriptionsPlugin,
    ].concat(IS_TEST_MODE ? [testUtilsPlugin] : []),
  })
}
