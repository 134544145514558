import { ChakraProvider } from '@chakra-ui/react'
import { useValues } from 'kea'
import { SideBar } from 'lib/components/SideBar/SideBar'
import { SaasPlanManagementModal } from 'lib/components/saas-plan-management/SaasPlanManagementModal'
import { authLogic } from 'lib/logics/authLogic'

import { Suspense, useEffect } from 'react'

import './GlobalStyles.scss'
import { DelayedSpinner } from './lib/components/DelayedSpinner/DelayedSpinner'
import { sceneLogic, scenes } from './lib/logics/sceneLogic'
import { theme } from './theme'

export function App(): JSX.Element {
  const { scene, params } = useValues(sceneLogic)
  const { isAuthenticated } = useValues(authLogic)

  const Scene = scenes[scene].component || scenes.error404.component

  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (link && process.env.NODE_ENV !== 'production') {
      link.href = '/favicon-dev.ico'
    }
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <SideBar hideSideBar={scenes[scene].hideSideBar ?? false}>
        <Suspense fallback={<DelayedSpinner />}>
          <Scene {...params} />
        </Suspense>
      </SideBar>
      {isAuthenticated && <SaasPlanManagementModal />}
    </ChakraProvider>
  )
}
