import { events, kea, path, selectors } from 'kea'
import { loaders } from 'kea-loaders'
import { api } from 'lib/Api'
import { DistributionCenter } from 'lib/Types'

import type { distributionCenterModelLogicType } from './distributionCenterModelLogicType'

export const distributionCenterModelLogic = kea<distributionCenterModelLogicType>([
  path(['scenes', 'distribution-center-settings', 'distributionCenterSettingsLogic']),
  loaders(() => ({
    distributionCenters: [
      [] as DistributionCenter[],
      {
        loadDistributionCenters: async () => {
          const url = '/api/distribution-centers/'
          const response = await api.get(url)
          return response['results']
        },
      },
    ],
  })),
  selectors(() => ({
    getDistributionCenterFromList: [
      (s) => [s.distributionCenters],
      (distributionCenters) => (distributionCenterId?: string) => {
        if (!distributionCenterId) {
          return null
        }
        return distributionCenters.find((p) => p.uuid === distributionCenterId) ?? null
      },
    ],
    activeDistributionCenterCount: [
      (s) => [s.distributionCenters],
      (distributionCenters) => distributionCenters.filter((dc) => dc.active).length,
    ],
    distributionCenterById: [
      (s) => [s.distributionCenters],
      (distributionCenters) => Object.fromEntries(distributionCenters.map((d) => [d.uuid, d])),
    ],
    inactiveDistributionCenters: [
      (s) => [s.distributionCenters],
      (distributionCenters) => distributionCenters.filter((dc) => !dc.active),
    ],
    activeDistributionCenters: [
      (s) => [s.distributionCenters],
      (distributionCenters) => distributionCenters.filter((dc) => dc.active),
    ],
  })),
  events(({ actions }) => ({
    afterMount: [actions.loadDistributionCenters],
  })),
])
